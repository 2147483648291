import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { type Props } from "../Media/types";

export const isBrowser = typeof window !== "undefined";
const Header = () => {
  const data: Props = useStaticQuery(graphql`
    query {
      allGalleryJson {
        nodes {
          id
          label
          captionImagenName
        }
      }
    }
  `);
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const isMedia = pathname.includes("media");
  const isAbout = pathname.includes("about");
  const isCapabilities = pathname.includes("capabilities");
  const [toggle, setToggle] = useState<boolean>(false);
  const [searchShow, setSearchShow] = useState<boolean>(false);
  const [showAbout, setShowAbout] = useState<boolean>(false);
  const [showRepairS, setShowRepairS] = useState<boolean>(false);
  const [showVideos, setShowVideos] = useState<boolean>(false);
  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [showMedia, setShowMedia] = useState<boolean>(false);
  const [stickyClass, setStickyClass] = useState("header--sticky header-one");

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", (event) => {
        const doc = event.target as Document;
        const scroll =
          doc.scrollingElement?.scrollTop != null
            ? doc.scrollingElement?.scrollTop
            : 0;

        if (
          scroll > 120 &&
          stickyClass !== "header--sticky header-one sticky"
        ) {
          setStickyClass("header--sticky header-one sticky");
        } else {
          setStickyClass("header--sticky header-one");
        }
      });
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", () => {});
      }
    };
  }, []);

  return (
    <>
      <header className={stickyClass}>
        <div
          className={`header-top header-top-one ${isHome ? "bg-1" : "bg-1-2"}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-xl-block d-none">
                <div className="left">
                  <div className="mail">
                    <a href="tel:+13055928807">
                      <i
                        className="fas fa-phone"
                        style={{ color: "white" }}
                      ></i>
                      305.592.8807
                    </a>
                  </div>
                  {isHome && (
                    <div className="mail">
                      <a
                        href="https://goo.gl/maps/epTp9bHSrVpGDdhD9"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fas fa-map-marker-alt"
                          style={{ color: "white" }}
                        ></i>
                        2775 NW 82 Ave, Doral, Florida 33122
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 d-xl-block d-none">
                <div className="right">
                  {/* <ul className="top-nav">
                    <li>
                      <a href="about-us.html">About</a>
                    </li>
                    <li>
                      <a href="blog-list.html">News</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul> */}
                  <ul className="top-nav social-wrapper-one">
                    <li>
                      <a
                        href="https://www.facebook.com/MekcoGroup/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/mekcogroup?lang=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/mekcogroup/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="mr--0"
                        href="https://www.linkedin.com/company/mekco-group-inc/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main-one bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="thumbnail">
                  <a href="/">
                    <img width={200} src="/images/logo-color.svg" alt="logo" />
                  </a>
                </div>
              </div>
              <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                <div className="main-header">
                  <nav className="nav-main mainmenu-nav d-none d-xl-block">
                    <ul className="mainmenu">
                      <li>
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="has-droupdown">
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/capabilities"
                        >
                          Capabilities
                        </Link>
                        <ul className="submenu">
                          <li>
                            <a href="/capabilities/repair-services">
                              Repair Services
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-link"
                          to="/media"
                        >
                          Media
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="nav-item"
                              to="#"
                              onClick={() => {
                                setShowVideos(!showVideos);
                              }}
                            >
                              Videos
                            </Link>
                            <ul className={showVideos ? "active" : ""}>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    isBrowser &&
                                      window.location.replace(
                                        "/media/videos/airfi-videos",
                                      );
                                  }}
                                >
                                  AirFi Videos
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    isBrowser &&
                                      window.location.replace(
                                        "/media/videos/mx14-videos",
                                      );
                                  }}
                                >
                                  Blend14 Videos
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    isBrowser &&
                                      window.location.replace(
                                        "/media/videos/satto-videos",
                                      );
                                  }}
                                >
                                  SATTO Videos
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link
                              className="nav-item"
                              to="#"
                              onClick={() => {
                                setShowGallery(!showGallery);
                              }}
                            >
                              Image Gallery
                            </Link>
                            <ul className={showGallery ? "active" : ""}>
                              {data?.allGalleryJson.nodes.map((e) => (
                                <li key={e.id}>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      isBrowser &&
                                        window.location.replace(
                                          `/media/gallery/${e.id}`,
                                        );
                                    }}
                                  >
                                    {e.label}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/about"
                        >
                          About Us
                        </Link>
                        <ul className="submenu">
                          <li>
                            <a href="/about/quality">
                              Quality & Certifications
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/clients"
                        >
                          Clients
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/careers"
                        >
                          Careers
                        </Link>
                      </li>
                      <li className="">
                        <Link activeStyle={{ color: "#f9b233" }} to="/news">
                          News
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          // activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/"
                        >
                          Clients
                        </Link>
                      </li> */}
                      {/* <li className="has-droupdown">
                        <a className="nav-link" href="#">
                          News
                        </a>
                        <ul className="submenu">
                          <li>
                            <a href="#">N1</a>
                          </li>
                          <li>
                            <a href="#">N2</a>
                          </li>
                        </ul>
                      </li> */}
                      {/*     <li>
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/services"
                        >
                          Services
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          activeStyle={{ color: "#f9b233" }}
                          className="nav-item"
                          to="/contact"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="button-area">
                    <button
                      onClick={() => {
                        setSearchShow((e) => !e);
                      }}
                      id="search"
                      className="rts-btn p-0"
                    >
                      <i className="far fa-search"></i>
                    </button>
                    <div
                      className={`search-input-area ${
                        searchShow ? "show" : ""
                      }`}
                    >
                      <div className="container">
                        <div className="search-input-inner">
                          <div className="input-div">
                            <input
                              id="searchInput1"
                              className="search-input"
                              type="text"
                              placeholder="Search by keyword or #"
                            />
                            <button>
                              <i className="far fa-search"></i>
                            </button>
                          </div>
                          <div id="close" className="search-close-icon">
                            <i className="far fa-times"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*     <a
                      href="#"
                      className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btn"
                    >
                      Get Quote
                    </a> */}
                    <button
                      onClick={() => {
                        setToggle((e) => !e);
                      }}
                      id="menu-btn"
                      className="menu ml--20 ml_sm--5"
                    >
                      <img
                        className="menu-dark"
                        src="/images/icon/menu.png"
                        alt="Menu-icon"
                      />
                      <img
                        className="menu-light"
                        src="/images/icon/menu-light.png"
                        alt="Menu-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="side-bar" className={`side-bar ${toggle ? "show" : ""}`}>
        <button
          onClick={() => {
            setToggle((e) => !e);
            setShowMedia(!showMedia);
          }}
          className="close-icon-menu"
        >
          <i className="far fa-times"></i>
        </button>

        <div className="rts-sidebar-menu-desktop">
          <a className="logo-1" href="/">
            <img
              className="logo"
              src="/images/logo-color.svg"
              alt="finbiz_logo"
            />
          </a>
          <a className="logo-2" href="/">
            <img
              className="logo"
              src="/images/logo/logo-4.svg"
              alt="finbiz_logo"
            />
          </a>
          <a className="logo-3" href="/">
            <img
              className="logo"
              src="/images/logo/logo-3.svg"
              alt="finbiz_logo"
            />
          </a>
          <a className="logo-4" href="/">
            <img
              className="logo"
              src="/images/logo/logo-5.svg"
              alt="finbiz_logo"
            />
          </a>
          <div className="body d-none d-xl-block">
            <p className="disc">
              We must explain to you how all seds this mistakens idea denouncing
              pleasures and praising account.
            </p>
            <div className="get-in-touch">
              <div className="h6 title">Get In Touch</div>

              <div className="wrapper">
                <div className="single">
                  <i className="fas fa-phone-alt"></i>
                  <a href="tel:+13055928807">(305) 592-8807</a>
                </div>

                <div className="single">
                  <i className="fas fa-envelope"></i>
                  <a href="mailto:sales@mekcogroup.com">sales@mekcogroup.com</a>
                </div>

                <div className="single">
                  <i className="fas fa-globe"></i>
                  <a href="#">www.webexample.com</a>
                </div>

                <div className="single">
                  <i className="fas fa-map-marker-alt"></i>
                  <a href="#">2775 NW 82 Ave, Doral, Florida 33122</a>
                </div>
              </div>
              <div className="social-wrapper-two menu">
                <a
                  href="https://www.facebook.com/MekcoGroup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/mekcogroup?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/mekcogroup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>

                <a
                  href="https://www.linkedin.com/company/mekco-group-inc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="body-mobile d-block d-xl-none">
            <nav className="nav-main mainmenu-nav">
              <ul className="mainmenu">
                <li className="">
                  <Link activeStyle={{ color: "#f9b233" }} to="/">
                    Home
                  </Link>
                </li>
                <li className="has-droupdown">
                  <Link
                    to="/capabilities"
                    style={isCapabilities ? { color: "#f9b233" } : {}}
                    className={showRepairS ? "open" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRepairS(!showRepairS);
                    }}
                  >
                    <span
                      onClick={() => {
                        isBrowser && window.location.replace("/capabilities");
                      }}
                    >
                      Capabilities
                    </span>
                  </Link>
                  <ul className={`submenu p-0${showRepairS ? " active" : ""}`}>
                    <li>
                      <a href="/capabilities/repair-services">
                        Repair Services
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link
                    style={isMedia ? { color: "#f9b233" } : {}}
                    to="/media"
                    className={showMedia ? "open" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMedia(!showMedia);
                    }}
                  >
                    <span
                      onClick={() => {
                        isBrowser && window.location.replace("/media");
                      }}
                    >
                      Media
                    </span>
                  </Link>
                  <ul className={`submenu p-0${showMedia ? " active" : ""}`}>
                    <li className="videos">
                      <Link
                        to="#"
                        onClick={() => {
                          setShowVideos(!showVideos);
                        }}
                      >
                        Videos
                      </Link>
                      <ul
                        className={`submenu p-0${showVideos ? " active" : ""}`}
                      >
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              isBrowser &&
                                window.location.replace(
                                  "/media/videos/airfi-videos",
                                );
                            }}
                          >
                            AirFi Videos
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              isBrowser &&
                                window.location.replace(
                                  "/media/videos/mx14-videos",
                                );
                            }}
                          >
                            Blend14 Videos
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              isBrowser &&
                                window.location.replace(
                                  "/media/videos/satto-videos",
                                );
                            }}
                          >
                            SATTO Videos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="gallery">
                      <Link
                        to="#"
                        onClick={() => {
                          setShowGallery(!showGallery);
                        }}
                      >
                        Image Gallery
                      </Link>
                      <ul
                        className={`submenu p-0${showGallery ? " active" : ""}`}
                      >
                        {data?.allGalleryJson.nodes.map((e) => (
                          <li key={e.id}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                isBrowser &&
                                  window.location.replace(
                                    `/media/gallery/${e.id}`,
                                  );
                              }}
                            >
                              {e.label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link
                    style={isAbout ? { color: "#f9b233" } : {}}
                    to="/about"
                    className={showAbout ? "open" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAbout(!showAbout);
                    }}
                  >
                    <span
                      onClick={() => {
                        isBrowser && window.location.replace("/about");
                      }}
                    >
                      About Us
                    </span>
                  </Link>
                  <ul className={`submenu p-0${showAbout ? " active" : ""}`}>
                    <li>
                      <a href="/about/quality">Quality & Certifications</a>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <Link activeStyle={{ color: "#f9b233" }} to="/clients">
                    Clients
                  </Link>
                </li>
                <li className="">
                  <Link activeStyle={{ color: "#f9b233" }} to="/careers">
                    Careers
                  </Link>
                </li>
                <li className="">
                  <Link activeStyle={{ color: "#f9b233" }} to="/news">
                    News
                  </Link>
                </li>
                {/*   <li className="">
                  <Link to="/services">Services</Link>
                </li> */}
                <li className="">
                  <Link activeStyle={{ color: "#f9b233" }} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="social-wrapper-two menu mobile-menu">
              <a
                href="https://www.facebook.com/MekcoGroup/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/mekcogroup?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/mekcogroup/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>

              <a
                href="https://www.linkedin.com/company/mekco-group-inc/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
            <a
              href="#"
              className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu p-0"
            >
              Get Quote
            </a>
          </div>
        </div>
      </div>

      <div
        id="anywhere-home"
        className={`${toggle ? "bgshow" : ""}`}
        onClick={() => {
          setToggle((e) => !e);
          setShowGallery(false);
          setShowVideos(false);
          setShowMedia(false);
        }}
      ></div>

      <div
        id="anywhere-home"
        className={`${searchShow ? "bgshow" : ""}`}
        onClick={() => {
          setSearchShow((e) => !e);
        }}
      ></div>
    </>
  );
};

export default Header;
