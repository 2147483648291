exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-quality-tsx": () => import("./../../../src/pages/about/quality.tsx" /* webpackChunkName: "component---src-pages-about-quality-tsx" */),
  "component---src-pages-about-team-details-[slug]-tsx": () => import("./../../../src/pages/about/team-details/[slug].tsx" /* webpackChunkName: "component---src-pages-about-team-details-[slug]-tsx" */),
  "component---src-pages-capabilities-index-tsx": () => import("./../../../src/pages/capabilities/index.tsx" /* webpackChunkName: "component---src-pages-capabilities-index-tsx" */),
  "component---src-pages-capabilities-repair-services-tsx": () => import("./../../../src/pages/capabilities/repair-services.tsx" /* webpackChunkName: "component---src-pages-capabilities-repair-services-tsx" */),
  "component---src-pages-careers-details-[id]-tsx": () => import("./../../../src/pages/careers/details/[id].tsx" /* webpackChunkName: "component---src-pages-careers-details-[id]-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-gallery-[id]-tsx": () => import("./../../../src/pages/media/gallery/[id].tsx" /* webpackChunkName: "component---src-pages-media-gallery-[id]-tsx" */),
  "component---src-pages-media-index-tsx": () => import("./../../../src/pages/media/index.tsx" /* webpackChunkName: "component---src-pages-media-index-tsx" */),
  "component---src-pages-media-videos-[slug]-tsx": () => import("./../../../src/pages/media/videos/[slug].tsx" /* webpackChunkName: "component---src-pages-media-videos-[slug]-tsx" */),
  "component---src-pages-news-[author]-author-tsx": () => import("./../../../src/pages/news/author/[author].tsx" /* webpackChunkName: "component---src-pages-news-[author]-author-tsx" */),
  "component---src-pages-news-[category]-category-tsx": () => import("./../../../src/pages/news/category/[category].tsx" /* webpackChunkName: "component---src-pages-news-[category]-category-tsx" */),
  "component---src-pages-news-[slug]-slug-tsx": () => import("./../../../src/pages/news/slug/[slug].tsx" /* webpackChunkName: "component---src-pages-news-[slug]-slug-tsx" */),
  "component---src-pages-news-[tag]-tag-tsx": () => import("./../../../src/pages/news/tag/[tag].tsx" /* webpackChunkName: "component---src-pages-news-[tag]-tag-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-query-[search]-search-tsx": () => import("./../../../src/pages/news/querySearch/[search].tsx" /* webpackChunkName: "component---src-pages-news-query-[search]-search-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-single-service-tsx": () => import("./../../../src/templates/single-service.tsx" /* webpackChunkName: "component---src-templates-single-service-tsx" */)
}

