import axios from "axios";
import { API_URL, GATSBY_SIGNATURE } from "@/npc-api/config";
import { type TagItem, type CategoryItem, type PostListItem } from "@/types";
import { type PostListResponse } from "@/context/types/blog.types";

interface RequestPostParamsProps {
  searchQuery?: string;
  pageSize?: number;
  page?: number;
  published?: boolean;
  scheduled?: boolean;
  categoryId?: string;
  tagSlug?: string;
  author?: string;
}

const loadCategories = async (): Promise<CategoryItem[]> => {
  try {
    const { data: postResponse } = await axios.get(`${API_URL}/posts`, {
      params: {
        published: true,
        scheduled: false,
        signature: GATSBY_SIGNATURE,
        state: "published",
      },
    });
    const categoriesMap: Record<string, CategoryItem> = {};

    postResponse.forEach((post: PostListItem) => {
      post.categories.forEach((category: CategoryItem) => {
        categoriesMap[category.id] = category;
      });
    });
    const uniqueCategories = Object.values(categoriesMap);

    return uniqueCategories.sort(() => Math.random() - 0.5);
  } catch (err) {
    console.error(err);
    return [];
  }
};
const loadTags = async (): Promise<TagItem[]> => {
  try {
    const { data: postResponse } = await axios.get(`${API_URL}/posts`, {
      params: {
        published: true,
        scheduled: false,
        signature: GATSBY_SIGNATURE,
        state: "published",
      },
    });
    const tagCounts: Record<string, TagItem & { count: number }> = {};

    postResponse.forEach((post) => {
      post.tags.forEach((tag) => {
        if (tag.name in tagCounts) {
          tagCounts[tag.name].count += 1;
        } else {
          tagCounts[tag.name] = {
            id: tag.id,
            name: tag.name,
            slug: tag.slug,
            count: 1,
          };
        }
      });
    });

    return Object.values(tagCounts)
      .sort((a, b) => b.count - a.count)
      .slice(0, 9);
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getBlogData = async ({
  pageSize,
  page,
  author,
  searchQuery,
  categoryId,
  tagSlug,
}: RequestPostParamsProps): Promise<PostListResponse> => {
  try {
    const { data: postResponse } = await axios.get(`${API_URL}/posts`, {
      params: {
        published: true,
        scheduled: false,
        signature: GATSBY_SIGNATURE,
        state: "published",
        pageSize,
        page: page - 1,
        author,
        searchQuery,
        categoryId,
        tagSlug,
      },
    });

    const results = postResponse.results;
    const total = postResponse.total;

    return { results, total };
  } catch (error) {
    console.error(error);
    return { results: [], total: 0 };
  }
};

const getPostBySlug = async (slug: string) => {
  try {
    const { data: post }: { data: PostListItem } = await axios.get(
      `${API_URL}/posts/slug/${slug}`,
    );
    return post;
  } catch (err) {
    console.error(err);
    // throw new Error('Error retriving post')
  }
};

export { getBlogData, getPostBySlug, loadCategories, loadTags };
