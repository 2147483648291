import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
  type BlogState,
  type BlogContextType,
  type ActionMap,
} from "./types/blog.types";
import { isBrowser } from "@/components/Header";
import { type TagItem, type CategoryItem, type PostListItem } from "@/types";
import {
  getBlogData,
  getPostBySlug,
  loadCategories,
  loadTags,
} from "@/hooks/usePost";

export enum Types {
  Initial = "INITIALIZE",
  LOADING = "LOADING",
  SET_PAGE = "SET_PAGE",
  SET_SELECTED_AUTHOR = "SET_SELECTED_AUTHOR",
  SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY",
  SET_RECENT_POST = "SET_RECENT_POST",
  SET_SEARCH = "SET_SEARCH",
  SET_SELECTED_TAG = "SET_SELECTED_TAG",
  GET_SINGLE_POST = "GET_SINGLE_POST",
  CLEAR_STORAGE = "CLEAR_STORAGE",
}

export type BlogActions = ActionMap<BlogPayload>[keyof ActionMap<BlogPayload>];

const initialState: BlogState = {
  isLoading: true,
  posts: [],
  totalPost: 0,
  page: 1,
  setPage: (page) => {
    dispatch({
      type: Types.SET_PAGE,
      payload: {
        page,
      },
    });
  },
  MAX_SIZE: 4,
  currentPost: {
    id: "",
    title: "",
    content: "",
    imageUrl: "",
    tags: [],
    categories: [],
    slug: "",
    state: "",
    shortDescription: "",
    createdAt: "",
    author: "",
  },
  categories: [],
  recentPosts: [],
  tags: [],
  selectedAuthor:
    isBrowser && window.localStorage.getItem("author") !== null
      ? String(window.localStorage.getItem("author"))
      : "",
  selectedCategory:
    isBrowser && window.localStorage.getItem("category") !== null
      ? String(window.localStorage.getItem("category"))
      : "",
  search:
    isBrowser && window.localStorage.getItem("search") !== null
      ? String(window.localStorage.getItem("search"))
      : "",
  selectedTag:
    isBrowser && window.localStorage.getItem("tag") !== null
      ? String(window.localStorage.getItem("tag"))
      : "",
};

const BlogContext = createContext<BlogContextType>({
  state: initialState,
  dispatch: () => null,
  fetchPBySlug: () => {},
  setAuthor: () => {},
  setIsLoading: () => {},
  setCategory: () => {},
  setSearch: () => {},
  setTag: () => {},
  clearStorage: () => {},
});

interface BlogPayload {
  [Types.Initial]: {
    posts: PostListItem[];
    totalPost: number;
    categories: CategoryItem[];
    tags: TagItem[];
  };
  [Types.LOADING]: {
    isLoading: boolean;
  };
  [Types.SET_PAGE]: {
    page: number;
  };
  [Types.GET_SINGLE_POST]: {
    currentPost: PostListItem;
  };
  [Types.SET_SELECTED_AUTHOR]: {
    author: string;
  };
  [Types.SET_SELECTED_CATEGORY]: {
    category: string;
  };
  [Types.SET_RECENT_POST]: {
    recentPosts: PostListItem[];
  };
  [Types.SET_SEARCH]: {
    search: string;
  };
  [Types.SET_SELECTED_TAG]: {
    selectedTag: string;
  };
  [Types.CLEAR_STORAGE]: {
    item: string;
  };
}

const BlogReducer = (state: BlogState, action: BlogActions): any => {
  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        posts: action.payload.posts,
        totalPost: action.payload.totalPost,
        categories: action.payload.categories,
        tags: action.payload.tags,
      };
    case Types.LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case Types.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case Types.GET_SINGLE_POST:
      return {
        ...state,
        currentPost: action.payload.currentPost,
      };
    case Types.SET_SELECTED_AUTHOR:
      return {
        ...state,
        selectedAuthor: action.payload.author,
      };
    case Types.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload.category,
      };
    case Types.SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      };
    case Types.SET_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.payload.selectedTag,
      };
    case Types.SET_RECENT_POST:
      return {
        ...state,
        recentPosts: action.payload.recentPosts,
      };
    case Types.CLEAR_STORAGE:
      if (isBrowser) {
        window.localStorage.removeItem(action.payload.item);

        if (action.payload.item === "author") {
          return {
            ...state,
            selectedAuthor: "",
          };
        } else if (action.payload.item === "category") {
          return {
            ...state,
            selectedCategory: "",
          };
        } else if (action.payload.item === "search") {
          return {
            ...state,
            search: "",
          };
        } else if (action.payload.item === "tag") {
          return {
            ...state,
            selectedTag: "",
          };
        }
      }
      return state;

    default:
      return state;
  }
};

const BlogProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(BlogReducer, initialState);

  const setTag = (tag: string) => {
    dispatch({
      type: Types.SET_SELECTED_TAG,
      payload: {
        selectedTag: tag,
      },
    });
  };
  const setSearch = (search: string) => {
    if (isBrowser) {
      dispatch({
        type: Types.SET_SEARCH,
        payload: {
          search,
        },
      });
    }
  };
  const setCategory = (category: string) => {
    dispatch({
      type: Types.SET_SELECTED_CATEGORY,
      payload: {
        category,
      },
    });
  };
  const setAuthor = (author: string) => {
    dispatch({
      type: Types.SET_SELECTED_AUTHOR,
      payload: {
        author,
      },
    });
  };
  const setIsLoading = (loading: boolean) => {
    dispatch({
      type: Types.LOADING,
      payload: {
        isLoading: loading,
      },
    });
  };

  const getRecentPost = async () => {
    try {
      const dataPost = await getBlogData({
        pageSize: 6,
        page: 1,
      });

      dispatch({
        type: Types.SET_RECENT_POST,
        payload: {
          recentPosts: dataPost.results,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPBySlug = async (slug: string) => {
    try {
      setIsLoading(true);
      const post = await getPostBySlug(slug);

      dispatch({
        type: Types.GET_SINGLE_POST,
        payload: {
          currentPost: post != null ? post : state.currentPost,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBlogData = async () => {
    try {
      setIsLoading(true);

      const blogData = await getBlogData({
        pageSize: state.MAX_SIZE,
        page: state.page,
        author:
          isBrowser && window.localStorage.getItem("author") !== null
            ? String(window.localStorage.getItem("author"))
            : "",
        categoryId:
          isBrowser && window.localStorage.getItem("category") !== null
            ? String(window.localStorage.getItem("category"))
            : "",
        searchQuery:
          isBrowser && window.localStorage.getItem("search") !== null
            ? String(window.localStorage.getItem("search"))
            : "",
        tagSlug:
          isBrowser && window.localStorage.getItem("tag") !== null
            ? String(window.localStorage.getItem("tag"))
            : "",
      });
      const categoriesData = await loadCategories();
      const tagsData = await loadTags();

      dispatch({
        type: Types.Initial,
        payload: {
          posts: blogData.results,
          totalPost: blogData.total,
          categories: categoriesData,
          tags: tagsData,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearStorage = (item: string) => {
    if (isBrowser) {
      window.localStorage.removeItem(item);

      if (item === "author") {
        dispatch({
          type: Types.CLEAR_STORAGE,
          payload: {
            item: "selectedAuthor",
          },
        });
      } else if (item === "category") {
        dispatch({
          type: Types.CLEAR_STORAGE,
          payload: {
            item: "selectedCategory",
          },
        });
      } else if (item === "search") {
        dispatch({
          type: Types.CLEAR_STORAGE,
          payload: {
            item: "search",
          },
        });
      } else if (item === "tag") {
        dispatch({
          type: Types.CLEAR_STORAGE,
          payload: {
            item: "selectedTag",
          },
        });
      }
    }
  };

  useEffect(() => {
    getRecentPost().catch((e) => {
      console.error(e);
    });
  }, []);

  useEffect(() => {
    if (isBrowser) {
      fetchBlogData().catch((e) => {
        console.error(e);
      });
    }
  }, [
    state.search,
    state.selectedCategory,
    state.selectedAuthor,
    state.selectedTag,
    state.page,
  ]);

  return (
    <BlogContext.Provider
      value={{
        state,
        dispatch,
        fetchPBySlug,
        setAuthor,
        setIsLoading,
        setCategory,
        setSearch,
        setTag,
        clearStorage,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

const useBlog = () => useContext(BlogContext);

export { BlogProvider, BlogContext, useBlog };
